import type { MyAccountWishlistProductCardProps } from '@design-system/components/MyAccount/Wishlist/MyAccountWishlistProductCard.props'
import type { ColorOption } from '@design-system/components/Pdp/PdpColorSelector.props'
import type { ProductCardHorizontalProps } from '@design-system/components/UI/UIProductCardHorizontal.props'
import type { CartFeedbackAddToCartProps } from '@design-system/components/Cart/CartFeedbackAddToCart.props'
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'

export const useWishlistQuickBuy = async (dialogID?: string) => {
  const { addToCart, lineItems } = useCart()
  const { dispatchAddToCartEvent } = useGACartEvents()
  const { openDialog } = useDialog()

  const lastAddedToCart = useState<
    null | (ProductCardHorizontalProps & CartFeedbackAddToCartProps)
  >('lastAddedToCart', () => null)

  const { removeItem } = await useWishlist({ dedupe: 'defer' })

  const quickBuyProduct = ref<ProductCardHorizontalProps>()

  const itemOptionColor = ref<ColorOption | null>()

  const buy = async (product: MyAccountWishlistProductCardProps) => {
    if (product.productSku) {
      await buyItemWishSize(product)
    } else {
      await buyItemWithoutSize(product)
    }
  }

  const buyItemWishSize = async (
    product: MyAccountWishlistProductCardProps
  ) => {
    await addToCart({
      name: product.title,
      sku_code: product.productSku,
      metadata: {
        size: `${product.selectedSize} (${product.selectedCountry})`,
        color: product.selectedColor,
        website: product.productBrand,
      },
    })
    dispatchAddToCartEvent({ view: 'wishlist_page' })

    const isAlreadyAdded = lineItems.value.some(
      ({ sku_code }) => sku_code === product.productSku
    )

    lastAddedToCart.value = {
      ...mapWishlistCard(product),
      isAlreadyAdded,
    }

    openDialog(SIDEBAR_IDS.cartFeedbackAddToCart)
    await removeItem(product.productCode)
  }

  const buyItemWithoutSize = async (
    product: MyAccountWishlistProductCardProps
  ) => {
    quickBuyProduct.value = mapWishlistCard(product)
    if (dialogID) {
      await nextTick()
      openDialog(dialogID)
    }
  }

  const mapWishlistCard = (product: MyAccountWishlistProductCardProps) => ({
    id: product.productCode,
    // for now it is ok, only to display correct informations
    quantity: 1,
    stockQty: 1,
    metadata: product.productBrand,
    info: {
      name: product.title,
      price: product.price,
      color: product.selectedColor,
      size:
        product.selectedSize &&
        `${product.selectedSize} (${product.selectedCountry})`,
    },
    path: '',
    image: product.selectedImage,
    available: product.isAvailable,
    skuCode: product.productSku ?? product.productCode,
    itemTotalPrice: product.price,
  })

  const checkBeforeRemoveFromWishlist = async () => {
    if (
      itemOptionColor.value?.sku ||
      itemOptionColor.value?.sku !== quickBuyProduct.value?.skuCode
    )
      return
    await removeItem(quickBuyProduct.value?.skuCode ?? '')
  }

  return {
    quickBuyProduct,
    itemOptionColor,
    checkBeforeRemoveFromWishlist,
    buyItemWithoutSize,
    buy,
  }
}
